import React, {useState, useRef} from 'react'
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { PrincipalContainer } from '../../components/containers/principalContainer'
import { TopBanner } from '../../components/topBanner'
import './index.css'
import { LoadingSpinner } from '../../components/loadingSpinner'
import axios from 'axios';
import Swal from 'sweetalert2'
import colorSquares from '../../assets/images/colorsSquares.png'


export const FormCredencial = () => {
  const [estado, setEstado] = useState(false); // Estado inicial
  const [loading, setLoading] = useState(false)
  const [gotStudentInfo, setGotStudentInfo] = useState(false)
  const [userInfo, setUserInfo] = useState({})
  const [user, setUser] = useState('')
  const [password, setPassword] = useState('')

//   const reportTemplateRef = useRef(null);

//   const handleGeneratePdf = () => {
//     const credencialContainer = document.querySelector('.printDivQr2');

//     // Captura el contenido del contenedor
//     html2canvas(credencialContainer).then(canvas => {
//         // Crea un objeto de imagen con los datos del canvas
//         const imgData = canvas.toDataURL('image/png');

//         // Crea un nuevo documento PDF
//         const pdf = new jsPDF();

//         // Agrega la imagen al PDF
//         pdf.addImage(imgData, 'PNG', 0, 0);

//         // Guarda el PDF
//         pdf.save("credencial.pdf");
//     });
// };


const handleClick = async () => {
  setLoading(true)
  //setGotStudentInfo(true)

  // if(user.length<2){
  //   setLoading(false)
  //   Swal.fire({
  //     title: 'Error!',
  //     text: 'Escribe tu usuario',
  //     icon: 'error',
  //     confirmButtonText: 'Aceptar'
  //   })
  // }

  // if(password.length<2){
  //   setLoading(false)
  //   Swal.fire({
  //     title: 'Error!',
  //     text: 'Escribe tu contraseña',
  //     icon: 'error',
  //     confirmButtonText: 'Aceptar'
  //   })
  // }



  try {
      // Enviar solicitud POST al endpoint
      const response = await axios.post('https://sii.tsj.mx:3002/sesion', {
          user:user,
          password:password
      });

      // Imprimir la respuesta en consola
      console.log(response.data);
      setGotStudentInfo(true)
      setLoading(false)
      setUserInfo(response.data)
  } catch (error) {
    setLoading(false)
    Swal.fire({
      title: 'Error!',
      text: 'Usuario y contraseña incorrecto',
      icon: 'error',
      confirmButtonText: 'Aceptar'
    })
  }

};




  const ReportTemplate = () => {
    const styles = {
      credencialContainer:{
        height:'450px',
        width:'963px',
        padding:'1%',
        borderRadius:'10px',
        margin:'auto',
        marginTop:'5vh',
        marginBottom:'5vh',
        display:'flex',
        position:'relative'
    },
    
    credencialPhoto:{
        width: '200px',
        height: '200px', 
        overflow: 'hidden',
        borderRadius:'10px',
    },
    
    credencialInfo:{
        /* background-color:pink; */
        marginLeft:'1%',
        textAlign:'left',
        zIndex:'10'
    },
    
    credencialInfoData:{
        fontFamily: 'madaniArabicLight',
    },
    
    qrCrdencialImage:{
        width:'300px',
        height:'300px',
        
        position:'absolute',
        right:'0',
        bottom:'0'
    },
    
    credencialSquares:{
    
        width:'80px',
        zIndex:'-5',
        position: 'absolute',
        bottom: '0',
        left: '0'
    },
    
    credencialSquaresImage:{
        width:'100%'
    },
    
    };


    return (
      <>
        <div style={styles.credencialContainer}>

        <div>
          <div style={styles.credencialPhoto}>
            <img style={styles.credencialPhoto} src='https://tecmm.edu.mx/web-tsj-designs/directores/DG-Iliana.jpeg'/>
          </div>
          </div>

          <div style={styles.credencialSquaresImage}>
            <img  src='https://tecmm.edu.mx/web-tsj-designs/colorsSquares.png'/>
          </div>


          <div style={styles.credencialInfo}>
            <h1>Iliana Janett Hernández Partida</h1>
            <h2 style={styles.credencialInfoData}>15011499</h2>
            <h2 style={styles.credencialInfoData}>Ingenieria en sistemas computacionales</h2>
            <h2 style={styles.credencialInfoData}>5to semestre</h2>
            <h2 style={styles.credencialInfoData}>TSJ Zapopan</h2>
            <h2 style={styles.credencialInfoData}>Activo</h2>

            <br/>
            <br/>
            <br/>
            <br/>
            <br/>


          </div>

          <div style={styles.qrCrdencialImage}>
            <img src='https://tecmm.edu.mx/web-tsj-designs/qrToDirectorio.png'/>
          </div>

        </div>
      </>
    );
  };


  const Print = () =>{     
    //console.log('print');
    let printContents = document.getElementById('printDivQr2').innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
   document.body.innerHTML = originalContents; 
  }



  const printDocument=()=>{
    const input = document.getElementById('divToPrint3');
    html2canvas(input, {useCORS:true})
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        pdf.addImage(imgData, 'JPEG', 50, 10, 100, 150 );
        // pdf.output('dataurlnewwindow');
        pdf.save("paseIngreso.pdf");
      })
    ;
  }

  const printDocumentMovil=()=>{
    const input = document.getElementById('divToPrint3');
    html2canvas(input, {useCORS:true})
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        pdf.addImage(imgData, 'JPEG', 50, 10, 100, 150 );
        // pdf.output('dataurlnewwindow');
        pdf.save("paseIngreso.pdf");
      })
    ;
  }

  

  const styles = {
    credencialContainer:{
      height:'450px',
      width:'963px',
      padding:'1%',
      borderRadius:'10px',
      margin:'auto',
      marginTop:'5vh',
      marginBottom:'5vh',
      display:'flex',
      position:'relative'
  },

  printDiv:{
    height:'450px',
    width:'963px',
    padding:'1%',
    borderRadius:'10px',
    margin:'auto',
    marginTop:'5vh',
    marginBottom:'5vh',
    display:'flex',
    position:'relative',
    border:'1px solid black'
  }
}



  return (
    <PrincipalContainer>
      {loading ? <LoadingSpinner/> : null}

      <TopBanner source={'https://tecmm.edu.mx/web-tsj-designs/topBanners/topBannerCredencial.png'}/>
      { gotStudentInfo ? 
        <>
        <div id='divToPrint3' className='credencialContainer'>


          <div className="credencialPhoto">
            <img style={{width:'100%'}} className='credencialPhoto'  src={userInfo.imagen}/>
          </div>


          <div id='squaresBottom' className="credencialSquares">
            <img src={colorSquares}/>
          </div>

          <div id='squaresTop' className="credencialSquares">
            <img src={colorSquares}/>
          </div>
          <br/>

          <div className='credencialInfo'>
            <h2 style={{textAlign:'center'}}>{userInfo.tipo}</h2>
            <h1>{userInfo.nombre}</h1>
            <br/>

            {userInfo.tipo === 'Alumno' ? 
              <>
                <h2><a>Matricula:</a> {userInfo.matricula}</h2>
                <h2><a>Carrera:</a> {userInfo.carrera}</h2>
                <h2><a>Semestre:</a> {userInfo.semestre}</h2>
                <h2><a>Unidad Academica:</a> {userInfo.campus}</h2>
                <h2><a>Periodo: </a>{userInfo.calendario}</h2>
                <h2><a>Status: </a>{userInfo.status === "Vigente"?"Activo":userInfo.status}</h2>
              </>

              :

              <>
                <h2><a>Area:</a> {userInfo.area}</h2>
                <h2><a>Puesto:</a> {userInfo.puesto}</h2>
                <h2><a>Unidad Academica:</a> {userInfo.campus}</h2>
                <h2><a>Numero de empleado:</a> {userInfo.noEmpleado}</h2>
              </>
            }



          </div>

          <div className='qrCredencial'>
            <div id="printImage" className="printDivQr" dangerouslySetInnerHTML={{ __html: userInfo.qr }}>

            </div>
          </div>
        
        </div>
        <button id='printCredencialDesktop' className='submitCredencial' type="submit" onClick={printDocument}> <span class="material-symbols-outlined"> print </span> Imprimir</button>
        <button id='printCredencialMovil' className='submitCredencial' type="submit" onClick={printDocumentMovil}> <span class="material-symbols-outlined"> print </span> Imprimir</button>
        </>
    
    
    
        :


              
        <div className='formContainer'>
            <img className='formLogoTsj' src='https://www.tecmm.edu.mx/web-tsj-designs/logoTsj.png'/>

            <br/>
            <br/>
            <br/>



            <h1>Pase de Ingreso</h1>
            <p className='descriptionCredencial'>Inicia sesion con tu correo electrónico y contraseña de Edcore para descargar tu pase de ingreso</p>


            <input className='inputCredencial' placeholder='Usuario' type="text" id="username" name="username" value={user} onChange={(e)=>setUser(e.target.value)}/>
            <br/>
            <input className='inputCredencial' placeholder='Contraseña' type="password" id="username" name="username" value={password} onChange={(e)=>setPassword(e.target.value)}/>
            <br/>
            <br/>


            <button id='loginSubmit' className='submitCredencial' type="submit" onClick={handleClick}>INGRESAR</button>
        </div>
      
    
    
      }



    </PrincipalContainer>
  )
}
