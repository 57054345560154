import React from 'react'
import { PrincipalContainer } from '../../components/containers/principalContainer'
import { Button, Card, Col, Container, Row, Stack } from 'react-bootstrap'
import { TopBanner } from '../../components/topBanner'

export const EstimuloDocente = () => {
  return (
    <PrincipalContainer>
        <TopBanner source={'https://tecmm.edu.mx/web-tsj-designs/topBanners/topBannerEstimuloDocente.png'}/>
        <Container style={{paddingTop:'2vh', paddingBottom:'2vh'}}>
            <Stack gap={3}>
                <Row>
                    <Col>

                    </Col>

                    <Col>
                        <Card>
                            <div style={{width:'100%', height:'15rem', backgroundColor:'#54c98f', display:'flex', flexDirection:'row', flexWrap:'wrap', justifyContent:'center', alignItems:'center'}}>
                                <div>
                                    <span style={{fontSize:'7rem', color:'white'}} class="material-symbols-outlined"> picture_as_pdf </span>
                                </div>
                            </div>
                            <Card.Body>
                            <Card.Text>
                                Circular PEDD 2024
                            </Card.Text>
                            <a target='_blank' href='http://tecmm.edu.mx/documentos/2024/estimuloDocente/circular_pedd_2024.pdf'>
                                <Button variant="primary">Descargar</Button>
                            </a>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col>
                        <Card>
                            <div style={{width:'100%', height:'15rem', backgroundColor:'#ff4d63', display:'flex', flexDirection:'row', flexWrap:'wrap', justifyContent:'center', alignItems:'center'}}>
                                <div>
                                    <span style={{fontSize:'7rem', color:'white'}} class="material-symbols-outlined"> folder_zip </span>
                                </div>
                            </div>

                            <Card.Body>
                            <Card.Text>
                                Estimulos 2024 
                            </Card.Text>

                            <a download href='http://tecmm.edu.mx/documentos/2024/estimuloDocente/estimulos_2024.zip'>
                                <Button variant="primary">Descargar</Button>
                            </a>

                            </Card.Body>
                            
                        </Card>
                    </Col>

                    <Col>

                    </Col>
                </Row>
            </Stack>
        </Container>
    </PrincipalContainer>
  )
}
