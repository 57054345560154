import React from 'react'
import { PrincipalContainer } from '../../components/containers/principalContainer'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { TopBanner } from '../../components/topBanner';
import './index.css'

export const Becas = () => {
  return (

    <PrincipalContainer>
      <TopBanner source={'https://tecmm.edu.mx/web-tsj-designs/topBanners/topBannerBecas.png'}/>
        <br/>
        <p className='parrafoDescription'>
        EL OTORGAMIENTO DE BECAS TIENE COMO PROPÓSITOS FUNDAMENTALES: 
        <br/>
        <br/>
        Propiciar que estudiantes del TJS en situación económica adversa y deseos de superación puedan continuar su proyecto educativo en el nivel de Educación Superior;
        <br/>
        <br/>
        Lograr la equidad educativa mediante la ampliación de oportunidades de acceso y permanencia en programas educativos de reconocida calidad ofrecidos por el TSJ. 
        <br/>
        <br/>
        Reducir la deserción escolar y propiciar la terminación oportuna de los estudios mediante el otorgamiento de apoyos, estímulos e Incentivos que fomenten una mayor retención de los estudiantes en los programas educativos;
        <br/>
        <br/>
        Impulsar la formación integral de nuestra comunidad estudiantil en áreas de conocimiento que requiera el desarrollo social, económico y cultura.
        </p>
        <br/>

        <h1>Becas activas: </h1>
        <br/>
            <Container>
      <Row>
        <Col></Col>

        <Col>
          <Card style={{ width: '18rem'}}>
            <Card.Img variant="top" src="https://tecmm.edu.mx/becasActivas/becalos/becalos.png" />
            <Card.Body>
                <Card.Title style={{minHeight:'3rem'  }}>Tu Futuro con Bécalos</Card.Title>
                <Card.Text>
                Con el objetivo de ampliar las oportunidades de acceso y permanencia en la educación de los y las jóvenes de nivel medio superior y superior, así como de contribuir a fortalecer sus conocimientos y habilidades, el Programa Bécalos convoca a estudiantes inscritos en una institución pública de educación media superior o superior a postularse para la obtención de un apoyo educativo mediante el Programa Trayecto Formativo Bécalos para el ciclo escolar 2024-2025.
                    <br/>
                    <br/>
                    Publicación de resultados: Lunes 2 de septiembre de 2024 en el sitio
                    <br/>
                    <br/>
                    Periodo de solicitud: Del 15 de julio al 16 de agosto de 2024

                </Card.Text>
                <a href='https://tecmm.edu.mx/becasActivas/becalos/convocatoriaBecalos2024.pdf'>
                  <Button variant="primary">Ver convocatoria</Button>
                </a>

            </Card.Body>
          </Card>
        
        
        </Col>
        
        
        
        <Col>
        
                    <Card style={{ width: '18rem'}}>
            <Card.Img variant="top" src="https://tecmm.edu.mx/becasActivas/becaSantander/becaSantander.png" />
            <Card.Body>
                <Card.Title style={{minHeight:'3rem'  }}>Beca Santander Apoyo a la manutención 2024</Card.Title>
                <Card.Text>
                  Programa de apoyo económico único de $9,000 pesos. ¡Esta es tu oportunidad! ¿Qué incluye esta fantástica beca? Un apoyo de manutención única de $9,000 pesos (nueve mil pesos 00/100 M.N.) directamente para ti, ¡para que puedas centrarte en tus metas académicas sin preocupaciones! 
                    <br/>
                    <br/>
                    Inscripciones primera fase: 7 de mayo al 21 de julio de 2024
                    <br/>
                    <br/>
                    Inscripciones segunda fase: 24 de julio al 11 de agosto de 2024
                    <br/>
                    <br/>
                    Plazo para realizar el curso requisito "Tecnologías emergentes" (autogestivo en línea y gratuito):11 de agosto de 2024
                    <br/>
                    <br/>
                    Entrega de resultados: 3 de septiembre de 2024
                    <br/>
                    <br/>
                    Plazo para aceptar la beca: 3 al 7 de septiembre de 2024
                </Card.Text>
                <a href='https://tecmm.edu.mx/becasActivas/becaSantander/becaSantander.pdf'>
                  <Button variant="primary">Ver convocatoria</Button>
                </a>

            </Card.Body>
            </Card>
        
        </Col>

        <Col>
                    <Card style={{ width: '18rem'}}>
            <Card.Img variant="top" src="https://tecmm.edu.mx/becasActivas/becaConahcyt/becaConahcyt.jpeg" />
            <Card.Body>
                <Card.Title style={{minHeight:'3rem' }}>CONVOCATORIA 2024 BECAS Y APOYOS COMPLEMENTARIOS PARA LA INCLUSIÓN</Card.Title>
                <Card.Text>
                Las "Becas y Apoyos Complementarios para la Inclusión de Madres Mexicanas Jefas de Familia, Estudiantes de Licenciatura" es un programa del Consejo Nacional de Humanidades, Ciencia y Tecnología (CONAHCYT) diseñado para apoyar a madres mexicanas que son jefas de familia y están cursando una licenciatura. Este programa busca contribuir a la equidad de género y a la inclusión social, facilitando el acceso, permanencia y conclusión de estudios superiores para estas mujeres.
                <br/>
                <br/>
                Fecha limite: 31 de enero de 2024
                </Card.Text>
                <a href='https://tecmm.edu.mx/becasActivas/becaConahcyt/BECA_CONAHCYT_2024.pdf'>
                  <Button variant="primary">Ver convocatoria</Button>

                </a>
            </Card.Body>
            </Card>
        
        
        </Col>

        <Col></Col>
      </Row>

    </Container>






    </PrincipalContainer>

  )
}
