import React from 'react'
import {  Routes, Route, useNavigate, redirect } from "react-router-dom";
import { Admision } from '../screens/admision';
import { Careers } from '../screens/careers';
import Directorio from '../screens/directorio/directorio';
import Landing from '../screens/landing';
import Noticia from '../screens/Noticia/index.jsx';
import NoticiaIliana from '../screens/Noticia/noticiaIliana.jsx';


import { PortalDenuncias } from '../screens/portalDenuncias';
import { LoginHokmalab } from '../screens/loginHokmalab';
import { Carrera } from '../screens/ofertaAcademica/carrera';
import { Unidades } from '../screens/unidades';
import QuienesSomos from '../screens/quienesSomos';
import { ContraloriaSocial } from '../screens/contraloriaSocial';
import { OfertaAcademicaByUnidad } from '../screens/ofertaAcademica/ofertaAcademicaByUnidad';
import { Transparencia } from '../screens/transparencia';
import { GestionLinea } from '../screens/ofertaAcademica/gestionLinea.jsx';
import { MaestriaSistemas } from '../screens/ofertaAcademica/maestriaSistemas.jsx';
import { MaestriaAdministracion } from '../screens/ofertaAcademica/maestriaAdministracion.jsx';
import { Turismo } from '../screens/ofertaAcademica/turismo.jsx';
import { Administracion } from '../screens/ofertaAcademica/administracion.jsx';
import { Becas } from '../screens/becas/index.jsx';
import { AvisoPrivacidad } from '../screens/avisoPrivacidad/index.jsx';
import { FormCredencial } from '../screens/credenciales/formCredencial.jsx';
import { ValidadorCredenciales } from '../screens/validadorCredenciales/index.jsx';
import { EstimuloDocente } from '../screens/estimuloDocente/index.jsx';
import { Bienvenida } from '../screens/bienvenida/index.jsx';
import { ComiteEtica } from '../screens/comiteEtica/index.jsx';
import { Normatividad } from '../screens/normatividad/index.jsx';

export const MainRouter = () => {
  return (
    <Routes>
    
         <Route path="/" element={<Landing />}/>
        <Route path="/OfertaAcademica" element={<Careers/>}/>
        <Route path="/Carreras/:campusId" element={<OfertaAcademicaByUnidad/>}/>
        <Route path='/admision' element={<Admision/>}/>
        <Route path='/directorio' element={<Directorio/>}/>
        <Route path='/portalDenuncias' element={<PortalDenuncias/>}/>
        <Route path="/Noticia/:noticiaNombre" element={<Noticia/>}/>
        <Route path="/Noticia/El-Tecnologico-Superior-de-Jalisco-recibe-a-su-nueva-Directora-General" element={<NoticiaIliana/>}/>
        <Route path="/diplomadoDocente" element={<LoginHokmalab/>}/>
        <Route path="/OfertaAcademica/:carreraId" element={<Carrera/>}/>
        <Route path="/OfertaAcademica/Ingenieria-en-Gestion-Empresarial-en-Linea" element={<GestionLinea/>}/>
        <Route path="/OfertaAcademica/Maestria-en-sistemas-computacionales" element={<MaestriaSistemas/>}/>
        <Route path="/OfertaAcademica/Maestria-en-administracion" element={<MaestriaAdministracion/>}/>
        <Route path="/OfertaAcademica/Licenciatura-en-turismo" element={<Turismo/>}/>
        <Route path="/OfertaAcademica/Licenciatura-en-administracion" element={<Administracion/>}/>
        <Route path="/UnidadesAcademicas" element={<Unidades/>}/>
        <Route path="/QuienesSomos" element={<QuienesSomos/>}/>
        <Route path="/ContraloriaSocial" element={<ContraloriaSocial/>}/>
        <Route path="/transparencia" element={<Transparencia/>}/>
        <Route path="/becas" element={<Becas/>}/>
        <Route path="/avisoPrivacidad" element={<AvisoPrivacidad/>}/>
        <Route path="/paseIngreso" element={<FormCredencial/>}/>
        <Route path="/validarCredencial/:qrId" element={<ValidadorCredenciales/>}/>
        <Route path="/estimuloDocente" element={<EstimuloDocente/>}/>
        <Route path="/bienvenida" element={<Bienvenida/>}/>
        <Route path="/comiteEtica" element={<ComiteEtica/>}/>
        <Route path="/normatividad" element={<Normatividad/>}/>
        
    </Routes>
  )
}
