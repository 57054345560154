import React from 'react'
import { PrincipalContainer } from '../../components/containers/principalContainer'
import { TopBanner } from '../../components/topBanner'
import Table from 'react-bootstrap/Table';

export const Normatividad = () => {
  return (
    <PrincipalContainer>
        <TopBanner source={'https://tecmm.edu.mx/web-tsj-designs/topBanners/topBannerNormatividad.png'}/>
        <div id='tableDirectorioComite'>
        <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                    <th>Documento</th>
                    <th>Enlace</th>

                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Reglamento estudiantil</td>
                        <td>
                            <a href='https://tecmm.edu.mx/documentos/normatividad2023/reglamento_estudiantil.pdf'>
                                Documento
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Protocolo de seguridad en caso de accidente o lesión en la escuela</td>
                        <td>
                            <a href='http://tecmm.edu.mx/normatividad/documentos/1-PROTOCOLO%20DE%20SEGURIDAD%20EN%20CASO%20DE%20ACCIDENTE%20O%20LESION%20EN%20LA%20ESCUELA.pdf'>
                                Documento
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>ACUERDO por el que se emiten las Principales Reglas de Registro y Valoración del Patrimonio.</td>
                        <td>
                            <a href='https://tecmm.edu.mx/documentos/normatividad2023/Acuerdo%20por%20el%20que%20se%20Emiten%20las%20Principales%20Reglas%20de%20Registro%20y%20Valoracion%20del%20Patrimonio.pdf'>
                                Documento
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>CÓDIGO DE CONDUCTA DEL INSTITUTO TECNOLÓGICO JOSÉ MARIO MOLINA PASQUEL Y HENRÍQUEZ</td>
                        <td>
                            <a href='https://tecmm.edu.mx/documentos/normatividad2023/Codigo%20de%20Condcuta%20del%20Instituto%20Tecnologico%20Jose%20Mario%20Molina%20Pasquel%20y%20Henriquez..pdf'>
                                Documento
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Codigo de ética del Estado de Jalisco</td>
                        <td>
                            <a href='https://tecmm.edu.mx/documentos/normatividad2023/Codigo%20de%20Etica%20del%20Estado%20de%20Jalisco.pdf'>
                                Documento
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Condiciones Generales de Trabajo del Instituto Tecnologico Jose Mario Molina Pasquel y Henriquez</td>
                        <td>
                            <a href='https://tecmm.edu.mx/documentos/normatividad2023/Condiciones%20Generales%20de%20Trabajo%20del%20Instituto%20Tecnologico%20Jose%20Mario%20Molina%20Pasquel%20y%20Henriquez.pdf'>
                                Documento
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>CONSTITUCIÓN POLÍTICA DEL ESTADO DE JALISCO</td>
                        <td>
                            <a href='https://tecmm.edu.mx/documentos/normatividad2023/Constitucio%cc%81n%20Poli%cc%81tica%20del%20Estado%20de%20Jalisco-160823.pdf'>
                                Documento
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>CONSTITUCIÓN POLÍTICA DE LOS ESTADOS UNIDOS MEXICANOS</td>
                        <td>
                            <a href='https://tecmm.edu.mx/documentos/normatividad2023/Constitucion%20Politica%20de%20los%20Estados%20Unidos%20Mexicanos%20que%20Reforma%20la%20de%205%20de%20Febrero%20de%201857.pdf'>
                                Documento
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>LEY FEDERAL DE PROTECCIÓN A LA PROPIEDAD INDUSTRIAL</td>
                        <td>
                            <a href='https://tecmm.edu.mx/documentos/normatividad2023/Ley%20Federal%20de%20Proteccio%cc%81n%20a%20la%20Propiedad%20Industrial..pdf'>
                                Documento
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>LEY FEDERAL DE PROTECCIÓN DE DATOS PERSONALES EN POSESIÓN DE LOS PARTICULARES</td>
                        <td>
                            <a href='https://tecmm.edu.mx/documentos/normatividad2023/Ley%20Federal%20de%20Proteccio%cc%81n%20de%20Datos%20Personales%20en%20Poesesio%cc%81n%20de%20los%20Sujetos%20Obligados..pdf'>
                                Documento
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>LEY GENERAL DE ARCHIVOS</td>
                        <td>
                            <a href='https://tecmm.edu.mx/documentos/normatividad2023/Ley%20General%20Archivo.pdf'>
                                Documento
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>LEY GENERAL DE CONTABILIDAD GUBERNAMENTAL</td>
                        <td>
                            <a href='https://tecmm.edu.mx/documentos/normatividad2023/Ley%20General%20de%20Contabilidad%20Gubernamental.pdf'>
                                Documento
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>LEY GENERAL DE EDUCACIÓN SUPERIOR</td>
                        <td>
                            <a href='https://tecmm.edu.mx/documentos/normatividad2023/Ley%20General%20de%20Educacion%20Superior.pdf'>
                                Documento
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>LEY GENERAL DE RESPONSABILIDADES ADMINISTRATIVAS</td>
                        <td>
                            <a href='https://tecmm.edu.mx/documentos/normatividad2023/Ley%20General%20de%20Responsabilidades%20Administrativas.pdf'>
                                Documento
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>LEY ORGÁNICA DEL PODER EJECUTIVO DEL ESTADO DE JALISCO</td>
                        <td>
                            <a href='https://tecmm.edu.mx/documentos/normatividad2023/Ley%20Orga%cc%81nica%20del%20Poder%20Ejecutivo%20del%20Estado%20de%20Jalisco-060723.pdf'>
                                Documento
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Ley Orgánica periódico Oficial del Estado de Jalisco</td>
                        <td>
                            <a href='https://tecmm.edu.mx/documentos/normatividad2023/Ley%20Organica%20periodico%20Oficial%20del%20Estado%20de%20Jalisco.%2023%20agosto%20del%202016.pdf'>
                                Documento
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>LEY DE ARCHIVOS DEL ESTADO DE JALISCO Y SUS MUNICIPIOS</td>
                        <td>
                            <a href='https://tecmm.edu.mx/documentos/normatividad2023/Ley%20de%20Archivos%20del%20Estado%20de%20Jalisco%20y%20sus%20Municipios%20-141122.pdf'>
                                Documento
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>LEY DE AUSTERIDAD Y AHORRO DEL ESTADO DE JALISCO Y SUS MUNICIPIOS</td>
                        <td>
                            <a href='https://tecmm.edu.mx/documentos/normatividad2023/Ley%20de%20Austeridad%20y%20Ahorro%20del%20Estado%20de%20Jalisco%20y%20sus%20Municipios-200223.pdf'>
                                Documento
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>LEY DE CIENCIA, DESARROLLO TECNOLÓGICO E INNOVACIÓN DEL ESTADO DE JALISCO</td>
                        <td>
                            <a href='https://tecmm.edu.mx/documentos/normatividad2023/Ley%20de%20Ciencia,%20Desarrollo%20Tecnolo%cc%81gico%20e%20Innovacio%cc%81n%20del%20Estado%20de%20Jalisco-300523.pdf'>
                                Documento
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>LEY DE EDUCACIÓN SUPERIOR DEL ESTADO DE JALISCO</td>
                        <td>
                            <a href='https://tecmm.edu.mx/documentos/normatividad2023/Ley%20de%20Educacio%cc%81n%20Superior%20del%20Estado%20de%20Jalisco.pdf'>
                                Documento
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>LEY DE EDUCACIÓN DEL ESTADO LIBRE Y SOBERANO DE JALISCO</td>
                        <td>
                            <a href='https://tecmm.edu.mx/documentos/normatividad2023/Ley%20de%20Educacio%cc%81n%20del%20Estado%20Libre%20y%20Soberano%20de%20Jalisco-170823.pdf'>
                                Documento
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>LEY DE ENTIDADES PARAESTATALES DEL ESTADO DE JALISCO</td>
                        <td>
                            <a href='https://tecmm.edu.mx/documentos/normatividad2023/Ley%20de%20Entidades%20Paraestatales%20del%20Estado%20de%20Jalisco-010323.pdf'>
                                Documento
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>LEY DE ENTREGA-RECEPCIÓN DEL ESTADO DE JALISCO Y SUS MUNICIPIOS</td>
                        <td>
                            <a href='https://tecmm.edu.mx/documentos/normatividad2023/Ley%20de%20Entrega%20Recepcio%cc%81n%20del%20Estado%20de%20Jalisco%20y%20sus%20Municipios-220223.pdf'>
                                Documento
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>LEY DE JUSTICIA ADMINISTRATIVA DEL ESTADO DE JALISCO</td>
                        <td>
                            <a href='https://tecmm.edu.mx/documentos/normatividad2023/Ley%20de%20Justicia%20Administrativa%20del%20Estado%20de%20Jalisco-150623.pdf'>
                                Documento
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>LEY DE JUSTICIA ALTERNATIVA DEL ESTADO DE JALISCO</td>
                        <td>
                            <a href='https://tecmm.edu.mx/documentos/normatividad2023/Ley%20de%20Justicia%20Alternativa%20del%20Estado%20de%20Jalisco-100823.pdf'>
                                Documento
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>LEY DE PROTECCIÓN DE DATOS PERSONALES EN POSESIÓN DE SUJETOS OBLIGADOS DEL ESTADO DE JALISCO Y SUS MUNICIPIOS</td>
                        <td>
                            <a href='https://tecmm.edu.mx/documentos/normatividad2023/Ley%20de%20Proteccio%cc%81n%20de%20Datos%20Personales%20en%20Posesio%cc%81n%20de%20Sujetos%20Obligados%20del%20Estado%20de%20Jalisco%20y%20sus%20Municipios%20-240223.pdf'>
                                Documento
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>LEY DE RESPONSABILIDADES POLÍTICAS Y ADMINISTRATIVAS DEL ESTADO DE JALISCO</td>
                        <td>
                            <a href='https://tecmm.edu.mx/documentos/normatividad2023/Ley%20de%20Responsabilidades%20Poli%cc%81ticas%20y%20Administrativas%20del%20Estado%20de%20Jalisco-170323.pdf'>
                                Documento
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>LEY DE TRANSPARENCIA Y ACCESO A LA INFORMACIÓN PÚBLICA DEL ESTADO DE JALISCO Y SUS MUNICIPIOS</td>
                        <td>
                            <a href='https://tecmm.edu.mx/documentos/normatividad2023/Ley%20de%20Transparencia%20y%20Acceso%20a%20la%20Informacio%cc%81n%20Pu%cc%81blica%20del%20Estado%20de%20Jalisco%20y%20sus%20Municipios-180823.pdf'>
                                Documento
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>LEY DEL PRESUPUESTO, CONTABILIDAD Y GASTO PÚBLICO DEL ESTADO DE JALISCO</td>
                        <td>
                            <a href='https://tecmm.edu.mx/documentos/normatividad2023/Ley%20del%20Presupuesto,%20Contabilidad%20%20y%20Gasto%20Pu%cc%81blico%20del%20Estado%20de%20Jalisco-280223.pdf'>
                                Documento
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>LEY DEL SISTEMA ANTICORRUPCIÓN DEL ESTADO DE JALISCO</td>
                        <td>
                            <a href='https://tecmm.edu.mx/documentos/normatividad2023/Ley%20del%20Sistema%20Anticorrupcio%cc%81n%20del%20Estado%20de%20Jalisco-150623.pdf'>
                                Documento
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>LEY PARA LOS SERVIDORES PÚBLICOS DEL ESTADO DE JALISCO Y SUS MUNICIPIOS</td>
                        <td>
                            <a href='https://tecmm.edu.mx/documentos/normatividad2023/Ley%20para%20los%20Servidores%20Pu%cc%81blicos%20del%20Estado%20de%20Jalisco%20y%20sus%20Municipios-130623.pdf'>
                                Documento
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>LINEAMIENTOS DE LENGUA EXTRANJERA</td>
                        <td>
                            <a href='https://tecmm.edu.mx/documentos/normatividad2023/Lineamiento%20de%20Lengua%20Extranjera.pdf'>
                                Documento
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Lineamiento para la Convalidación de Estudios</td>
                        <td>
                            <a href='https://tecmm.edu.mx/documentos/normatividad2023/Lineamiento%20para%20la%20Convalidacion%20de%20Estudios.pdf'>
                                Documento
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Lineamiento para la Operación del Programa de Tutoría</td>
                        <td>
                            <a href='https://tecmm.edu.mx/documentos/normatividad2023/Lineamiento%20para%20la%20Operacio%cc%81n%20del%20Programa%20de%20Tutori%cc%81a.pdf'>
                                Documento
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Lineamiento para la Operación y Acreditación del Servicio Social</td>
                        <td>
                            <a href='https://tecmm.edu.mx/documentos/normatividad2023/Lineamiento%20para%20la%20Operacio%cc%81n%20y%20Acreditacio%cc%81n%20del%20Servicio%20Social.pdf'>
                                Documento
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Lineamiento para la Operación y Acreditación de la Residencia</td>
                        <td>
                            <a href='https://tecmm.edu.mx/documentos/normatividad2023/Lineamiento%20para%20la%20Operacio%cc%81n%20y%20Acreditacio%cc%81n%20de%20la%20Residencia%20Profesional.pdf'>
                                Documento
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Lineamiento para la Titulación Integral</td>
                        <td>
                            <a href='https://tecmm.edu.mx/documentos/normatividad2023/Lineamiento%20para%20la%20Titulacion%20Integral.pdf'>
                                Documento
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>LINEAMIENTOS DE CONDICIONES ACADÉMICAS</td>
                        <td>
                            <a href='https://tecmm.edu.mx/documentos/normatividad2023/Lineamientos%20de%20Condiciones%20Academicas%20Versin%201.0.pdf'>
                                Documento
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Lineamientos del Sistema Institucional de Archivos y Grupo Interdisciplinario del Instituto Tecnologico Jose Mario Molina Pasquel y Henriquez</td>
                        <td>
                            <a href='https://tecmm.edu.mx/documentos/normatividad2023/Lineamientos%20del%20Sistema%20Institucional%20de%20Archivos%20y%20Grupo%20Interdisciplinario%20del%20Instituto%20Tecnologico%20Jose%20Mario%20Molina%20Pasquel%20y%20Henriquez..pdf'>
                                Documento
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Protocolo Cero para Prevenir, Atender, Sancionar y Erradicar el Acoso y Hostigamiento Sexual</td>
                        <td>
                            <a href='https://tecmm.edu.mx/documentos/normatividad2023/Protocolo%20Cero%20para%20Prevenir,%20Atender,%20Sancionar%20y%20Erradicar%20el%20Acoso%20y%20Hostigamiento%20Sexual.pdf'>
                                Documento
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Reglamento de la Ley de Austeridad y Ahorro del Estado de Jalisco y sus Municipios, para el Poder Ejecutivo del Estado</td>
                        <td>
                            <a href='https://tecmm.edu.mx/documentos/normatividad2023/Reglamento%20de%20la%20Ley%20de%20Austeridad%20y%20Ahorro%20para%20el%20Poder%20Ejecutivo.pdf'>
                                Documento
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Reglamento de la Ley de Compras Gubernamentales, Enajenación y Contratación de Servicios del Estado de Jalisco</td>
                        <td>
                            <a href='https://tecmm.edu.mx/documentos/normatividad2023/Reglamento%20de%20la%20Ley%20de%20Compras%20Gubernamentales,%20Enajenacio%cc%81n%20y%20Contratacio%cc%81n%20de%20Servicios%20del%20Estado%20de%20Jalisco%20.pdf'>
                                Documento
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Reglamento de la Ley de Entrega Recepción para el Poder Ejecutivo del Estado de Jalisco</td>
                        <td>
                            <a href='https://tecmm.edu.mx/documentos/normatividad2023/Reglamento%20de%20la%20Ley%20de%20Entrega%20Recepcio%cc%81n%20para%20el%20Poder%20Ejecutivo%20del%20Estado%20de%20Jalisco.pdf'>
                                Documento
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>REGLAMENTO DE LA LEY DE FOMENTO A LA CIENCIA, LA TECNOLOGÍA E INNOVACIÓN DEL ESTADO DE JALISCO</td>
                        <td>
                            <a href='https://tecmm.edu.mx/documentos/normatividad2023/Reglamento%20de%20la%20Ley%20de%20Fomento%20a%20la%20Ciencia,%20la%20Tecnologi%cc%81a%20e%20Innovacio%cc%81n%20del%20Estado%20de%20Jalisco.pdf'>
                                Documento
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Reglamento de la Ley de Planeacion para el Estado de Jalisco y sus Municipios</td>
                        <td>
                            <a href='https://tecmm.edu.mx/documentos/normatividad2023/Reglamento%20de%20la%20Ley%20de%20Planeacion%20para%20el%20Estado%20de%20Jalisco%20y%20sus%20Municipios.pdf'>
                                Documento
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>Reglamento de la Ley de Transparencia y Acceso a la Información Pública</td>
                        <td>
                            <a href='https://tecmm.edu.mx/documentos/normatividad2023/Reglamento%20de%20la%20Ley%20de%20Transparencia%20y%20Acceso%20a%20la%20Informacio%cc%81n%20Pu%cc%81blica.pdf'>
                                Documento
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td>REGLAMENTO DE LA LEY DEL PRESUPUESTO, CONTABILIDAD Y GASTO PUBLICO</td>
                        <td>
                            <a href='https://tecmm.edu.mx/documentos/normatividad2023/Reglamento%20de%20la%20Ley%20del%20Presupuesto,%20contabilida%20y%20Gasto%20Publico.pdf'>
                                Documento
                            </a>
                        </td>
                    </tr>
                </tbody>
            </Table>
            </div>
    </PrincipalContainer>
  )
}
