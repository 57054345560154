import React, { useEffect, useState } from 'react'
import { PrincipalContainer } from '../../components/containers/principalContainer'
import { TopBanner } from '../../components/topBanner'
import { LoadingSpinner } from '../../components/loadingSpinner'
import '../credenciales/index.css'
import { useParams } from 'react-router-dom'
import { Alert } from 'react-bootstrap'
import axios from 'axios'

export const ValidadorCredenciales = () => {

    const [loading, setLoading] = useState(false)
    const [userFound, setUserFound] = useState({})
    const {qrId} = useParams()

    useEffect(() => {
        console.log("QR ID:", qrId)
        handleInfo()
        // Cambiar el estado después de 3 segundos
        // setTimeout(() => {

        //     setLoading(false)
        //     setUserFound(true)
        // }, 3000);
    }, [])


    const handleInfo = async () => {
        setLoading(true)
        try {
      
            // Enviar solicitud POST al endpoint
            const response = await axios.get(`https://sii.tsj.mx:3002/validacion/${qrId}`);
      
            // Imprimir la respuesta en consola
            console.log(response.data);
            // setGotStudentInfo(true)
            setLoading(false)
            setUserFound(response.data)
        } catch (error) {
            console.error('Error:', error);
        }
      };
    

  return (
    <PrincipalContainer>
        {loading ? <LoadingSpinner/> : null}
        <TopBanner source={'https://tecmm.edu.mx/web-tsj-designs/topBanners/topBannerValidadorCredenciales.png'}/>

        {userFound && 
        <>
                <div className='credencialContainer'>

                    <div>
                    <div className="credencialPhoto">
                    <img className='credencialPhoto' src={userFound.imagen}/>
                    </div>
                    </div>

                    <div className="credencialSquares">
                    <img  src='https://tecmm.edu.mx/web-tsj-designs/colorsSquares.png'/>
                    </div>


                    <div className='credencialInfo'>
                    <h2 style={{textAlign:'center'}}>{userFound.tipo}</h2>
                    <h1>{userFound.nombre}</h1>
                    <h2><a>Matricula:</a> {userFound.matricula}</h2>
                    <h2><a>Carrera:</a> {userFound.carrera}</h2>
                    <h2><a>Semestre:</a> {userFound.semestre}</h2>
                    <h2><a>Unidad Academica:</a> {userFound.campus}</h2>
                    <h2><a>Periodo: </a>{userFound.calendario}</h2>
                    <h2><a>Status: </a>{userFound.status}</h2>

                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>

                    </div>


                    </div>
        
        
        
        
        </>
        
        
        }


    </PrincipalContainer>
  )
}
