import React, { useEffect, useState } from 'react'
import { PrincipalContainer } from '../../components/containers/principalContainer'
import './index.css'
import { useParams } from "react-router-dom";
import CarrerasData, { CarrerasDataBetter } from '../../helpers/ofertaAcademicaData.js'
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import YouTube from 'react-youtube';


export const MaestriaSistemas = (props) => {
  const {carreraId} = useParams()
  const [carreraSelected, setCarreraSelected] = useState({
    name: 'Maestria-en-Sistemas-Computacionales',
    card:'https://tecmm.edu.mx/web-tsj-designs/ofertaAcademica/Carreras-02.jpg',
    portada: 'https://tecmm.edu.mx/web-tsj-designs/portadasCarreras/Carreras-Banners-01.jpg',
    youtube:'rdWPqiy3RRc',
    videoInstitucional: "https://www.tecmm.edu.mx/videos/carreras/30_sist_computacionales.mp4",
    campus: 'ZAPOPAN',
    campusNoAccent: 'ZAPOPAN',
    objetivo: "Formar líderes de excelencia con sólidos conocimientos en sistemas computacionales de alto nivel competitivo que analicen, diseñen y desarrollen software, en las áreas de multimedia y cómputo ubicuo.",
    perfilEgreso : [
                    {perfil:"El egresado de la maestría será un profesionista competente capaz de proponer, diseñar, implementar y desarrollar software de cómputo ubicuo o multimedia, teniendo la habilidad para participar en equipos multidisciplinarios y en la identificación de problemas o áreas de oportunidad, aportando propuestas de solución aplicando las metodologías y tecnologías de información emergentes que cumplan con estándares internacionales. Tendrá la habilidad para mejorar la toma de decisiones, que contribuya a resolver eficazmente los problemas de las organizaciones privadas y públicas, en el uso de tecnologías de vanguardia."},
                    {perfil:"Analizar, desarrollar y programar modelos matemáticos, estadísticos y de simulación."},
                    {perfil:"Reconocer y guiarse por los aspectos sociales, profesionales y éticos en su entorno."},
                    {perfil:"Dirigir y coordinar equipos de trabajo multi e interdisciplinarios."},
                    {perfil:"Coordinar y realizar investigaciones que fortalezcan el desarrollo cultural, científico y tecnológico."},
                    {perfil:"Desarrollar y administrar sistemas de información, redes de computadoras y aplicaciones distribuidas."},
                    {perfil:"Poseer una visión empresarial y detectar áreas de oportunidad para emprender y desarrollar proyectos aplicando las tecnologías de información y de las comunicaciones."},
                    {perfil:"Seleccionar y administrar los recursos humanos y computacionales para unidades de servicios de cómputo."},
                    {perfil:"Estar comprometido con el desarrollo sustentable, respetando el entorno social y cultural donde se desarrollan las organizaciones."},
                    {perfil:"Desarrollar y administrar software de aplicación y de base."},
                    {perfil:"Aplicar nuevas tecnologías a la solución de problemas de su entorno laboral."},
    ],
    reticula: null,
    folleto: 'http://tecmm.edu.mx/folletosCarreras/Folleto-Maestria-Sistemas-Computacionales.pdf',
    convocatoria: "http://tecmm.edu.mx/recursos/convocatoriaMaestrias/CONVOCATORIA-MTRIA-EN-SISTEMAS.pdf",
  })

  const [modalVideoInstitucionalShow, setModalVideoInstitucionalShow] = useState(false);
  const [modalVideoTestimonialShow, setModalVideoTestimonialShow] = useState(false);


  




  const VideoInstitucionalModal = (props2) => {
    return (
      <Modal
        {...props2}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Video Institucional
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <YouTube
            videoId={carreraSelected.videoInstitucional}
          /> */}
          <video width="700px" height="500px" controls="controls" autoPlay>
            <source src={carreraSelected.videoInstitucional} type="video/mp4" />
          </video>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={props2.onHide}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  const VideoTestimonialModal = (props3) => {
    return (
      <Modal
        {...props3}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Video Testimonial
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <YouTube
            videoId={carreraSelected.youtube}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={props3.onHide}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
    );
  }


    return (
      <PrincipalContainer>


        <VideoInstitucionalModal
          show={modalVideoInstitucionalShow}
          onHide={() => setModalVideoInstitucionalShow(false)}
        />

        <VideoTestimonialModal
          show={modalVideoTestimonialShow}
          onHide={() => setModalVideoTestimonialShow(false)}
        />
        <img className='portadaCarrera' src={carreraSelected.portada}/>
        <Container>
          <Row>
            <Col onClick={()=> window.location.href = "https://admision.tsj.mx/"} className='buttonCarreraDocument' style={{backgroundColor:'#308fff'}} sm>
              <h1>Inscríbete</h1>
            </Col>
            <Col onClick={()=> setModalVideoTestimonialShow(true)} className='buttonCarreraDocument' style={{backgroundColor:'#54c98f'}} sm>
              <h1>Video Testimonial</h1>
            </Col>
            <Col onClick={()=> setModalVideoInstitucionalShow(true)} className='buttonCarreraDocument' style={{backgroundColor:'#ffae31'}} sm>
              <h1>Video Institucional</h1>
            </Col>
            {/* <Col onClick={()=> window.location.href = carreraSelected.reticula} className='buttonCarreraDocument' style={{backgroundColor:'#33179c'}} sm>
              <h1>Retícula</h1>
            </Col>
            <Col onClick={()=> window.location.href = carreraSelected.planEstudios} className='buttonCarreraDocument' style={{backgroundColor:'#ff4d63'}} sm>
              <h1>Plan de estudios</h1>
            </Col> */}
            <Col onClick={()=> window.location.href = carreraSelected.folleto} className='buttonCarreraDocument' style={{backgroundColor:'#33179c'}} sm>
              <h1>Folleto</h1>
            </Col>
          </Row>
        </Container>

        <br/> 

        <Container>
          <Row>
            <Col sm>
              <h1 className='tituloSeccionH1'>OBJETIVO</h1>
            </Col>
          </Row>
          <Row>
            <Col sm>
              <p className='objetivoP'>{carreraSelected.objetivo}</p>
            </Col>
          </Row>
        </Container>
        
        <br/>
        {carreraSelected.perfilIngreso?
          <Container>
            <Row>
              <Col sm>
                <h1 className='tituloSeccionH1'>PERFIL DE INGRESO</h1>
              </Col>
            </Row>
            <Row>
              <Col  className="perfilEgresoList" sm>
                  {carreraSelected.perfilIngreso.map((it)=>(
                    <li> {it.perfil} </li>
                  ))}
              </Col>
            </Row>
          </Container>
        :null
      
      }  


        <br/>

        <Container>
          <Row>
            <Col sm>
              <h1 className='tituloSeccionH1'>PERFIL DE EGRESO</h1>
            </Col>
          </Row>
          <Row>
            <Col  className="perfilEgresoList" sm>
                {carreraSelected.perfilEgreso.map((it)=>(
                  <li> {it.perfil} </li>
                ))}
            </Col>
          </Row>
        </Container>

        <Container>
          <Row>
            <Col sm>
              <h1 className='tituloSeccionH1'>OFERTADA EN: </h1>
            </Col>
          </Row>
          <Row>
            <Col sm>
              <p className='objetivoP'>{carreraSelected.campus}</p>
            </Col>
          </Row>
        </Container>
        

        
      
      </PrincipalContainer>
    )
  
}
