
import React, {useState, useEffect} from 'react'
import './index.css'
import { Spinner } from 'react-bootstrap'

export const LoadingSpinner = () => {
    const [textoCargando, setTextoCargando] = useState('Cargando'); // Estado para el texto de carga

    useEffect(() => {
        const intervalo = setInterval(() => {
            setTextoCargando(prevTexto => {
                return prevTexto === 'Cargando...' ? 'Cargando' : prevTexto + '.';
            });
        }, 500); // Cambia cada medio segundo

        return () => clearInterval(intervalo); // Limpiar el intervalo cuando el componente se desmonte
    }, []);

  return (
    <div className='loadingSpinnerContainer'>

        <div className='logoContainer'>
            <div>
                <img className='formLogoTsjSpinner' src='https://www.tecmm.edu.mx/web-tsj-designs/logoTsjWhite.png'/>

                {/* <h1>{textoCargando}</h1> */}
                <h1>Cargando...</h1>
            </div>
            
        </div>


    </div>
  )
}
