import React from 'react'
import { PrincipalContainer } from '../../components/containers/principalContainer'
import './index.css'
import { useParams } from "react-router-dom";
import CarrerasData, { CarrerasDataBetter } from '../../helpers/ofertaAcademicaData.js'
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import YouTube from 'react-youtube';

export const GestionLinea = () => {


    // const VideoInstitucionalModal = (props2) => {
    //     return (
    //       <Modal
    //         {...props2}
    //         size="lg"
    //         aria-labelledby="contained-modal-title-vcenter"
    //         centered
    //       >
    //         <Modal.Header closeButton>
    //           <Modal.Title id="contained-modal-title-vcenter">
    //             Video Institucional
    //           </Modal.Title>
    //         </Modal.Header>
    //         <Modal.Body>
    //           {/* <YouTube
    //             videoId={carreraSelected.videoInstitucional}
    //           /> */}
    //           <video width="700px" height="500px" controls="controls" autoPlay>
    //             <source src={'J6izdocayPA'} type="video/mp4" />
    //           </video>
    //         </Modal.Body>
    
    //         <Modal.Footer>
    //           <Button onClick={props2.onHide}>Cerrar</Button>
    //         </Modal.Footer>
    //       </Modal>
    //     );
    //   }
    
    //   const VideoTestimonialModal = (props3) => {
    //     return (
    //       <Modal
    //         {...props3}
    //         size="lg"
    //         aria-labelledby="contained-modal-title-vcenter"
    //         centered
    //       >
    //         <Modal.Header closeButton>
    //           <Modal.Title id="contained-modal-title-vcenter">
    //             Video Testimonial
    //           </Modal.Title>
    //         </Modal.Header>
    //         <Modal.Body>
    //           <YouTube
    //             videoId={carreraSelected.youtube}
    //           />
    //         </Modal.Body>
    
    //         <Modal.Footer>
    //           <Button onClick={props3.onHide}>Cerrar</Button>
    //         </Modal.Footer>
    //       </Modal>
    //     );
    //   }
    return (
        <PrincipalContainer>
  

          <>
          {/* <VideoInstitucionalModal
            show={modalVideoInstitucionalShow}
            onHide={() => setModalVideoInstitucionalShow(false)}
          />
  
          <VideoTestimonialModal
            show={modalVideoTestimonialShow}
            onHide={() => setModalVideoTestimonialShow(false)}
          /> */}
          <img className='portadaCarrera' src='http://tecmm.edu.mx/imagesReact/images/ofertaAcademicaNueva/Ing-Gestion-Empresarial-Linea.jpg'/>
          <Container>
            <Row>
              <Col onClick={()=> window.location.href = "https://admision.tsj.mx/"} className='buttonCarreraDocument' style={{backgroundColor:'#308fff'}} sm>
                <h1>Inscríbete</h1>
              </Col>
              {/* <Col onClick={()=> setModalVideoTestimonialShow(true)} className='buttonCarreraDocument' style={{backgroundColor:'#54c98f'}} sm>
                <h1>Video Testimonial</h1>
              </Col>
              <Col onClick={()=> setModalVideoInstitucionalShow(true)} className='buttonCarreraDocument' style={{backgroundColor:'#ffae31'}} sm>
                <h1>Video Institucional</h1>
              </Col> */}
              <Col onClick={()=> window.location.href = 'http://tecmm.edu.mx/reticulasCarreras/Reticula-Ingenieria-en-Gestion-Empresarial.pdf'} className='buttonCarreraDocument' style={{backgroundColor:'#33179c'}} sm>
                <h1>Retícula</h1>
              </Col>
              <Col onClick={()=> window.location.href = 'http://tecmm.edu.mx/planesEstudioCarreras/INGENIERA__EN_GESTIN_EMPRESARIAL_IGEM-2009-201.pdf'} className='buttonCarreraDocument' style={{backgroundColor:'#ff4d63'}} sm>
                <h1>Plan de estudios</h1>
              </Col>
              <Col onClick={()=> window.location.href = 'http://tecmm.edu.mx/folletosCarreras/newFolletos/Folleto-Ingenieria-en-Gestion-Empresarial.pdf'} className='buttonCarreraDocument' style={{backgroundColor:'#308fff'}} sm>
                <h1>Folleto</h1>
              </Col>
            </Row>
          </Container>
  
          <br/> 
  

          <Container>
            <Row>
              <Col sm>
                <h1 className='tituloSeccionH1'>OBJETIVO</h1>
              </Col>
            </Row>
            <Row>
              <Col sm>
                <p className='objetivoP'>Formar íntegramente profesionales que contribuyan a lagestión de empresas e innovación de procesos; así como al diseño, implementación y desarrollo de sistemas estratégicos de negocios, optimizando recursos en un entorno global, con ética y responsabilidad social.</p>
              </Col>
            </Row>
          </Container>
          
          <br/>

            <Container>
              <Row>
                <Col sm>
                  <h1 className='tituloSeccionH1'>REQUISITOS</h1>
                </Col>
              </Row>
              <Row>
                <Col  className="" sm>

                  <li>Certificado de nivel bachillerato o documento equivalente</li>
                  <li>Acta de Nacimiento</li>
                  <li>INE o documento de identificación</li>
                </Col>
              </Row>
            </Container>

            <br/>
          
            <Container>
              <Row>
                <Col sm>
                  <h1 className='tituloSeccionH1'>PERFIL DE INGRESO</h1>
                </Col>
              </Row>
              <Row>
                <Col  className="perfilEgresoList" sm>

                    <li>Capacidad de observación, análisis y síntesis.</li>
                    <li>Habilidad verbal, facilidad de expresión, sentido de organización.</li>
                    <li>Habilidad para comprender problemas sociales.</li>
                    <li>Objetividad y don de mando.</li>
                    <li>Habilidad de persuasión.</li>
                    <li>Habilidad numérica.</li>
                    <li>Habilidad para relacionarse con las personas, sociabilidad.</li>
                </Col>
              </Row>
            </Container>

  
  
          <br/>
  
          <Container>
            <Row>
              <Col sm>
                <h1 className='tituloSeccionH1'>PERFIL DE EGRESO</h1>
              </Col>
            </Row>
            <Row>
              <Col  className="perfilEgresoList" sm>
                <li>Desarrollar y aplicar habilidades directivas y la ingeniería en el diseño, creación, gestión, desarrollo, fortalecimiento e innovación de las organizaciones, con una orientación sistémica y sustentable para la toma de decisiones en forma efectiva.</li>
                <li>Diseñar e innovar estructuras administrativas y procesos, con base en las necesidades de las organizaciones para competir eficientemente en mercados globales.</li>
                <li>Gestionar eficientemente los recursos de la organización con visión compartida, con el fin de suministrar bienes y servicios de calida.</li>
                <li>Aplicar métodos cuantitativos y cualitativos para el análisis e interpretación de datos y modelado de sistemas, en los procesos organizacionales para la mejora continua, atendiendo estándares de calidad nunciial.</li>
                <li>Diseñar, evaluar y emprender nuevos negocios y proyectos empresariales, que promuevan el desarrollo sustentable y la responsabilidad social, en un mercado competitivo</li>
                <li>Diseñar e implementar estrategias de mercadotecnia basadas en información recopilada de fuentes primarias y secundarias del consumidor o usuario de algún producto, de acuerdo a oportunidades y amenazas del mercado.</li>
                <li>Establecer prograrnas para el fortalecimiento de la seguridad e higiene en las organizaciones.</li>
                <li>Gestionar sistemas integrales de calidad, ejerciendo un liderazgo efectivo y un compromiso ético, aplicando las herramientas básicas de la ingeniería.</li> 
                <li>Interpretar y aplicar normas legales que incidan en la creación y desarrollo de las organizaciones.</li>
                <li>Integrar, dirigir y desarrollar equipos de trabajo para la mejora continua y el crecimiento integral de las organizaciones.</li>
                <li>Analizar e interpretar la información financiera para detectar oportunidades de mejora e inversión en un mundo global, que incidan en la rentabilidad del negocio.</li>
                <li>Utilizar las nuevas tecnologías de información en la organización, para optimizar los procesos de comunicación y eficientar la toma de decisiones.</li>     
                <li>Propiciar el desarrollo del capital humano, para la realización de los objetivos organizacionales, dentro de un marco ético y un contexto multicultural.</li> 
                <li>Aplicar métodos de investigación para desarrollar e innovar sisternas, procesos y productos en las diferentes dimensiones de la organización.</li>
                <li>Analizar e interpretar la economía global para facilitar la toma de decisiones en la organización.</li>

              </Col>
            </Row>
          </Container>

          <br/>

          <Container>
            <Row>
              <Col sm>
                <h1 className='tituloSeccionH1'>Conoce la CONVOCATORIA JUL-AGO 2024 <a href='https://www.tecmm.edu.mx/documentos/tsjLinea/convocatoria_IGEM2024.pdf'>AQUÍ</a> </h1>
              </Col>
            </Row>
            <Row>
              <Col sm>
                <p className='objetivoP'>Si tienes alguna duda, puedes enviar un correo a: <a href='mailto:hola@enlinea.tsj.mx'>hola@enlinea.tsj.mx</a></p>
              </Col>
            </Row>
          </Container>

          <br/>
  
          <Container>
            <Row>
              <Col sm>
                <h1 className='tituloSeccionH1'>OFERTADA EN: </h1>
              </Col>
            </Row>
            <Row>
              <Col sm>
                <p className='objetivoP'>TSJ en linea</p>
              </Col>
            </Row>
          </Container>
          
          </>
          
          
  
          
        
        </PrincipalContainer>
      )
}
