import React, { useEffect } from 'react'

export const Admision = () => {
    useEffect(() => {
        window.location.replace('https://admision.tsj.mx')
    }, [])

    
    
  return (
    <div></div>
  )
}