import React, { useEffect, useState } from 'react'
import { PrincipalContainer } from '../../components/containers/principalContainer'
import './index.css'
import { useParams } from "react-router-dom";
import CarrerasData, { CarrerasDataBetter } from '../../helpers/ofertaAcademicaData.js'
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import YouTube from 'react-youtube';


export const MaestriaAdministracion = (props) => {
  const {carreraId} = useParams()
  const [carreraSelected, setCarreraSelected] = useState({
    name: 'Maestria-en-Administracion',
    card:'https://tecmm.edu.mx/web-tsj-designs/ofertaAcademica/Carreras-03.jpg',
    portada: 'https://tecmm.edu.mx/web-tsj-designs/portadasCarreras/Carreras-Banners-17.jpg',
    youtube:'U7SZz2s2qLY',
    videoInstitucional: "p2zos8KuCxY",
    campus: 'PUERTO VALLARTA',
    campusNoAccent: 'PUERTO VALLARTA',
    objetivo: "La Maestría en Administración, tiene como objetivo formar recursos humanos con una alta capacidad creativa e innovadora, con la destreza para el manejo de conocimientos, técnicas y habilidades que les permitan hacer frente a la problemática empresarial de forma racional, eficaz y eficientemente, aplicando los recursos que les permitan incrementar la innovación, la productividad y la tecnología, generando soluciones que mejoran la competitividad en las organizaciones y generar cambios en el entorno social, industrial y económico de Puerto Vallarta y del país.",

    perfilEgreso : [
                    {perfil:"Al término de sus estudios, el maestrante habrá fortalecido sus conocimientos, actitudes y habilidades directivas, que contribuyan al desarrollo económico y social de su ámbito de influencia, capaz de promover oportunidades de negocios, diseñar y operar nuevos modelos administrativos, establecer estrategias para impulsar la competitividad de las organizaciones y fortalecerlas de forma creativa e innovadora."},
                    {perfil:"El egresado de la maestría en administración será capaz de:"},
                    {perfil:"Analizar, crear y desarrollar proyectos, diseñar estrategias y prepararse para la toma de decisiones en las organizaciones."},
                    {perfil:"Ser directivos que tomen decisiones y/o que resuelvan problemas relacionados con los aspectos de la alta dirección y los negocios en un ámbito nacional e internacional."},
                    {perfil:"Generar nuevos conocimientos y soluciones para elevar la competitividad institucional a través de la innovación, productividad y desarrollo tecnológico desde una perspectiva nacional e internacional."},
                    {perfil:"Desarrollar nuevas alternativas de crecimiento y permanencia en el sector empresarial económicamente productivo desde una perspectiva de alta dirección."},
                    {perfil:"Crear y desarrollar planes de acción estratégicos para fortalecer la actividad económica- institucional en un micro y macro entorno."},
    ],
    reticula: null,
    folleto: 'http://tecmm.edu.mx/folletosCarreras/Folleto-Maestria-Administracion.pdf',
    convocatoria: "http://tecmm.edu.mx/recursos/convocatoriaMaestrias/CONVOCATORIA-MTRIA-EN-ADMINISTRACION.pdf",

  })

  const [modalVideoInstitucionalShow, setModalVideoInstitucionalShow] = useState(false);
  const [modalVideoTestimonialShow, setModalVideoTestimonialShow] = useState(false);


  




  const VideoInstitucionalModal = (props2) => {
    return (
      <Modal
        {...props2}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Video Institucional
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <YouTube
            videoId={carreraSelected.videoInstitucional}
          /> */}
          <video width="700px" height="500px" controls="controls" autoPlay>
            <source src={carreraSelected.videoInstitucional} type="video/mp4" />
          </video>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={props2.onHide}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  const VideoTestimonialModal = (props3) => {
    return (
      <Modal
        {...props3}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Video Testimonial
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <YouTube
            videoId={carreraSelected.youtube}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={props3.onHide}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
    );
  }


    return (
      <PrincipalContainer>


        <VideoInstitucionalModal
          show={modalVideoInstitucionalShow}
          onHide={() => setModalVideoInstitucionalShow(false)}
        />

        <VideoTestimonialModal
          show={modalVideoTestimonialShow}
          onHide={() => setModalVideoTestimonialShow(false)}
        />
        <img className='portadaCarrera' src={carreraSelected.portada}/>
        <Container>
          <Row>
            <Col onClick={()=> window.location.href = "https://admision.tsj.mx/"} className='buttonCarreraDocument' style={{backgroundColor:'#308fff'}} sm>
              <h1>Inscríbete</h1>
            </Col>
            <Col onClick={()=> setModalVideoTestimonialShow(true)} className='buttonCarreraDocument' style={{backgroundColor:'#54c98f'}} sm>
              <h1>Video Testimonial</h1>
            </Col>
            <Col onClick={()=> setModalVideoInstitucionalShow(true)} className='buttonCarreraDocument' style={{backgroundColor:'#ffae31'}} sm>
              <h1>Video Institucional</h1>
            </Col>
            {/* <Col onClick={()=> window.location.href = carreraSelected.reticula} className='buttonCarreraDocument' style={{backgroundColor:'#33179c'}} sm>
              <h1>Retícula</h1>
            </Col>
            <Col onClick={()=> window.location.href = carreraSelected.planEstudios} className='buttonCarreraDocument' style={{backgroundColor:'#ff4d63'}} sm>
              <h1>Plan de estudios</h1>
            </Col> */}
            <Col onClick={()=> window.location.href = carreraSelected.folleto} className='buttonCarreraDocument' style={{backgroundColor:'#33179c'}} sm>
              <h1>Folleto</h1>
            </Col>
          </Row>
        </Container>

        <br/> 

        <Container>
          <Row>
            <Col sm>
              <h1 className='tituloSeccionH1'>OBJETIVO</h1>
            </Col>
          </Row>
          <Row>
            <Col sm>
              <p className='objetivoP'>{carreraSelected.objetivo}</p>
            </Col>
          </Row>
        </Container>
        
        <br/>
        {carreraSelected.perfilIngreso?
          <Container>
            <Row>
              <Col sm>
                <h1 className='tituloSeccionH1'>PERFIL DE INGRESO</h1>
              </Col>
            </Row>
            <Row>
              <Col  className="perfilEgresoList" sm>
                  {carreraSelected.perfilIngreso.map((it)=>(
                    <li> {it.perfil} </li>
                  ))}
              </Col>
            </Row>
          </Container>
        :null
      
      }  


        <br/>

        <Container>
          <Row>
            <Col sm>
              <h1 className='tituloSeccionH1'>PERFIL DE EGRESO</h1>
            </Col>
          </Row>
          <Row>
            <Col  className="perfilEgresoList" sm>
                {carreraSelected.perfilEgreso.map((it)=>(
                  <li> {it.perfil} </li>
                ))}
            </Col>
          </Row>
        </Container>

        <Container>
          <Row>
            <Col sm>
              <h1 className='tituloSeccionH1'>OFERTADA EN: </h1>
            </Col>
          </Row>
          <Row>
            <Col sm>
              <p className='objetivoP'>{carreraSelected.campus}</p>
            </Col>
          </Row>
        </Container>
        

        
      
      </PrincipalContainer>
    )
  
}
