import React, { useEffect, useState } from 'react'
import { PrincipalContainer } from '../../components/containers/principalContainer'
import './index.css'
import { useParams } from "react-router-dom";
import CarrerasData, { CarrerasDataBetter } from '../../helpers/ofertaAcademicaData.js'
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import YouTube from 'react-youtube';


export const Administracion = (props) => {
  const {carreraId} = useParams()
  const [carreraSelected, setCarreraSelected] = useState({
    name: 'Licenciatura-en-Administracion',
    card:'https://tecmm.edu.mx/web-tsj-designs/ofertaAcademica/Carreras-24.jpg',
    portada: 'https://tecmm.edu.mx/web-tsj-designs/portadasCarreras/Carreras-Banners-21.jpg',
    youtube:'Hgv_R9b-TGs',
    videoInstitucional: "https://www.tecmm.edu.mx/videos/carreras/35_lic_administracion.mp4",
    campus: 'LA HUERTA, TEQUILA',
    campusNoAccent: 'LA HUERTA, TEQUILA',
    objetivo: "Formar profesionales de la administración capaces de actuar como agentes de cambio, a través del diseño, innovación y dirección en organizaciones, sensibles a las demandas sociales y oportunidades del entorno, con capacidad de intervención en ámbitos globales y con un firme propósito de observar las normas y los valores universales.",
    perfilIngreso : [
                    {perfil:"Pensamiento complejo, orientado a dar soluciones"},
                    {perfil:"Sinergia, capacidad de realizar trabajo en equipo"},
                    {perfil:"Inteligencia emocional, razonar objetivamente bajo presión"},
                    {perfil:"Liderazgo, él como factor de cohesión"},
                    {perfil:"Responsabilidad social, poseer un marco de referencia ética evidente"},
                    {perfil:"Habilidad en las ciencias económico-administrativas."},
                    {perfil:"Comunicación oral y escrita, evidentes"},
    ],
    perfilEgreso : [
                    {perfil:"Integrar los procesos gerenciales, de administración, de innovación y las estrategias de dirección para la competitividad y productividad de las organizaciones."},
                    {perfil:"Aplicar los conocimientos modernos de la gestión de negocios a las fases del proceso administrativo para la optimización de recursos y el manejo de los cambios organizacionales."},
                    {perfil:"Desarrollar las habilidades directivas y de vinculación basadas en la ética y la responsabilidad social, que le permitan integrar y coordinar equipos interdisciplinarios, para favorecer el crecimiento de la organización y su entorno global."},
                    {perfil:"Crear y desarrollar proyectos sustentables aplicando herramientas administrativas y métodos de investigación de vanguardia, con un enfoque estratégico, multicultural y humanista."},
                    {perfil:"Conducir la organización hacia la consecución de sus objetivos mediante un esfuerzo coordinado y espíritu emprendedor."},
                    {perfil:"Crear organizaciones que contribuyan a la transformación económica y social, identificando las oportunidades de negocios en un contexto global."},
                    {perfil:"Conocer y aplicar el marco legal vigente nacional e internacional de las organizaciones."},
                    {perfil:"Analizar e interpretar información financiera y económica para la toma de decisiones en las organizaciones."},
                    {perfil:"Ser un agente de cambio con la habilidad de potenciar el capital humano para la solución de los problemas y la toma de decisiones que las organizaciones afrontan."},
                    {perfil:"Implementar y administrar sistemas de gestión de calidad para orientarlos a la mejora continua, con la finalidad de lograr la productividad de la organización, desarrollando una cultura de calidad total."},
                    {perfil:"Aplicar las tecnologías de la información y comunicación para optimizar el trabajo y desarrollo de la organización."},
                    {perfil:"Actualizar conocimientos permanentemente para responder a los cambios globales."},
                    {perfil:"Diseñar sistemas de organización considerando alternativas estratégicas que generen cadenas productivas en beneficio de la sociedad."},
                    {perfil:"Tener visión multidisciplinaria para generar propuestas y desarrollar acciones de manera inmediata ante escenarios de contingencia."},

    ],
    reticula: 'http://tecmm.edu.mx/reticulasCarreras/Reticula-Licenciatura-en-Administracion.pdf',
    planEstudios: 'http://tecmm.edu.mx/planesEstudioCarreras/LICENCIATURA_EN_ADMINISTRACIN_LADM-2010-234.pdf',
    folleto: 'http://tecmm.edu.mx/folletosCarreras/newFolletos/Folleto-Licenciatura-en-Administracion.pdf',
    convocatoria: "",
  })

  const [modalVideoInstitucionalShow, setModalVideoInstitucionalShow] = useState(false);
  const [modalVideoTestimonialShow, setModalVideoTestimonialShow] = useState(false);


  




  const VideoInstitucionalModal = (props2) => {
    return (
      <Modal
        {...props2}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Video Institucional
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <YouTube
            videoId={carreraSelected.videoInstitucional}
          /> */}
          <video width="700px" height="500px" controls="controls" autoPlay>
            <source src={carreraSelected.videoInstitucional} type="video/mp4" />
          </video>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={props2.onHide}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  const VideoTestimonialModal = (props3) => {
    return (
      <Modal
        {...props3}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Video Testimonial
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <YouTube
            videoId={carreraSelected.youtube}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={props3.onHide}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
    );
  }


    return (
      <PrincipalContainer>


        <VideoInstitucionalModal
          show={modalVideoInstitucionalShow}
          onHide={() => setModalVideoInstitucionalShow(false)}
        />

        <VideoTestimonialModal
          show={modalVideoTestimonialShow}
          onHide={() => setModalVideoTestimonialShow(false)}
        />
        <img className='portadaCarrera' src={carreraSelected.portada}/>
        <Container>
          <Row>
            <Col onClick={()=> window.location.href = "https://admision.tsj.mx/"} className='buttonCarreraDocument' style={{backgroundColor:'#308fff'}} sm>
              <h1>Inscríbete</h1>
            </Col>
            <Col onClick={()=> setModalVideoTestimonialShow(true)} className='buttonCarreraDocument' style={{backgroundColor:'#54c98f'}} sm>
              <h1>Video Testimonial</h1>
            </Col>
            <Col onClick={()=> setModalVideoInstitucionalShow(true)} className='buttonCarreraDocument' style={{backgroundColor:'#ffae31'}} sm>
              <h1>Video Institucional</h1>
            </Col>
            <Col onClick={()=> window.location.href = carreraSelected.reticula} className='buttonCarreraDocument' style={{backgroundColor:'#33179c'}} sm>
              <h1>Retícula</h1>
            </Col>
            <Col onClick={()=> window.location.href = carreraSelected.planEstudios} className='buttonCarreraDocument' style={{backgroundColor:'#ff4d63'}} sm>
              <h1>Plan de estudios</h1>
            </Col>
            <Col onClick={()=> window.location.href = carreraSelected.folleto} className='buttonCarreraDocument' style={{backgroundColor:'#308fff'}} sm>
              <h1>Folleto</h1>
            </Col>
          </Row>
        </Container>

        <br/> 

        <Container>
          <Row>
            <Col sm>
              <h1 className='tituloSeccionH1'>OBJETIVO</h1>
            </Col>
          </Row>
          <Row>
            <Col sm>
              <p className='objetivoP'>{carreraSelected.objetivo}</p>
            </Col>
          </Row>
        </Container>
        
        <br/>
        {carreraSelected.perfilIngreso?
          <Container>
            <Row>
              <Col sm>
                <h1 className='tituloSeccionH1'>PERFIL DE INGRESO</h1>
              </Col>
            </Row>
            <Row>
              <Col  className="perfilEgresoList" sm>
                  {carreraSelected.perfilIngreso.map((it)=>(
                    <li> {it.perfil} </li>
                  ))}
              </Col>
            </Row>
          </Container>
        :null
      
      }  


        <br/>

        <Container>
          <Row>
            <Col sm>
              <h1 className='tituloSeccionH1'>PERFIL DE EGRESO</h1>
            </Col>
          </Row>
          <Row>
            <Col  className="perfilEgresoList" sm>
                {carreraSelected.perfilEgreso.map((it)=>(
                  <li> {it.perfil} </li>
                ))}
            </Col>
          </Row>
        </Container>

        <Container>
          <Row>
            <Col sm>
              <h1 className='tituloSeccionH1'>OFERTADA EN: </h1>
            </Col>
          </Row>
          <Row>
            <Col sm>
              <p className='objetivoP'>{carreraSelected.campus}</p>
            </Col>
          </Row>
        </Container>
        

        
      
      </PrincipalContainer>
    )
  
}
