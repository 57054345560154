import React from 'react'
import { PrincipalContainer } from '../../components/containers/principalContainer'
import Table from 'react-bootstrap/Table';
import './index.css'
import { TopBanner } from '../../components/topBanner';


export const ComiteEtica = () => {
  return (
    <PrincipalContainer>
        <TopBanner source={'https://tecmm.edu.mx/web-tsj-designs/topBanners/topBannerComiteEtica.png'}/>

        <div id='tableDirectorioComite'>
            <p>El Comité es el órgano colegiado responsable de llevar a cabo la implementación y seguimiento oportuno y eficaz de las acciones previstas en el acuerdo mediante el cual se crea la Unidad Especializada en Ética, Conducta y Prevención de Conflictos de Interés de la Administración Pública del Estado y los comités en las materias referidas en las dependencias y entidades de la Administración Pública del Estado y aquellas que le sean determinadas por la Unidad Especializada, en el ámbito de la entidad pública de su respectiva adscripción.</p>
            <p>Para presentar formalmente una queja ante el comite de etica puedes hacerlo a traves del portal <a href='https://www.tecmm.edu.mx/portalDenuncias'>tecmm.edu.mx/portalDenuncias</a> o bien puedes hacerlo de manera presencial en Camino arenero 1101, col. El Bajio, Zapopan, Jalisco, en la oficina del area juridica</p>
            <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                    <th>Puesto</th>
                    <th>Nombre</th>
                    <th>Area de ascripcion</th>
                    <th>Extension</th>
                    <th>Correo electronico</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>PRESIDENCIA</td>
                        <td>ERNESTO ALEJANDRO CASTELLANOS SILVA</td>
                        <td>Titular del area juridica</td>
                        <td>1006</td>
                        <td>quejascomite.etica@tecmm.edu.mx</td>
                    </tr>
                    <tr>
                        <td>SECRETARIO EJECUTIVO</td>
                        <td>JULIÁN SANTIAGO ARCIENEGA</td>
                        <td>coordinador de los Contencioso y Control Disciplinario</td>
                        <td>1006</td>
                        <td>quejascomite.etica@tecmm.edu.mx</td>
                    </tr>
                    <tr>
                        <td>VOCAL</td>
                        <td>SUSY YADIRA RODRÍGUEZ HERNÁNDEZ</td>
                        <td>Innovación Educativa</td>
                        <td>1003</td>
                        <td>susy.rodriguez@tecmm.edu.mx</td>
                    </tr>
                    <tr>
                        <td>VOCAL</td>
                        <td>VERÓNICA LÓPEZ PÉREZ</td>
                        <td>Desarrollo Académico</td>
                        <td>1003</td>
                        <td>veronica.lopez@tecmm.edu.mx</td>
                    </tr>
                    <tr>
                        <td>VOCAL</td>
                        <td>ITZEL MONSERRAT CORTES MARTÍNEZ</td>
                        <td>Coordinación Técnica General del Despacho</td>
                        <td>1003</td>
                        <td>itzel.cortes@tecmm.edu.mx</td>
                    </tr>
                    <tr>
                        <td>VOCAL</td>
                        <td>GILLERMO ANTONIO ANGUIANO APODACA</td>
                        <td>Director de Extensión y Vinculación</td>
                        <td>1003</td>
                        <td>antonio.anguiano@tecmm.edu.mx</td>
                    </tr>
                </tbody>
            </Table>
        </div>



    </PrincipalContainer>
  )
}
