import React from 'react'
import { PrincipalContainer } from '../../components/containers/principalContainer'
import './index.css'

export const Bienvenida = () => {
  return (
    <PrincipalContainer>

        <img className='photoIlliana' src='https://tecmm.edu.mx/web-tsj-designs/directores/DG-Iliana.jpeg'/>

        <p className='pBienvenida'>Les saludo de la manera mas cordial, manifestando principalmente mi compromiso con el Tecnológico Superior de Jalisco. Estamos aquí para velar por el bienestar de los estudiantes y colaboradores de nuestra institución. El día de hoy nos encomendamos a seguir trabajando con ese amor apasionado que me caracteriza en todas las encomiendas encausadas, con integridad, que para mí es un muy importante en todas las acciones, cuidando la imagen impecable de lo que queremos proyectar en nuestra institución, además de procurar innovación proactiva y el desarrollo trascendente.</p>

        <p className='pBienvenida'>Estamos aquí para facilitar los procesos de cambio y completar la transformación digital, además de impulsar la excelencia educativa, mejorar la planeación, resaltar los procesos de internacionalización, así como completar el proyecto de Gobierno del Estado de Jalisco de trabajar en mejores instalaciones, mejores equipamientos, espacios más dignos, construir nuevos edificios, continuar con el impulso al TSJ en Línea y sobre todo la transformación  de modelos educativos, normativos y de gestión.</p>

        <p className='pBienvenida'>El Tecnológico Superior de Jalisco con sus Unidades Académicas existentes, en construcción y extensiones, suman la segunda Universidad más grande del Estado de Jalisco. Además, formamos parte del Tecnológico Nacional de México, una de las universidades más grandes de América Latina con más de 600,000 estudiantes alrededor de nuestro país. Es por ello que asumir esta posición conlleva una gran responsabilidad, lo cual implica tener sabiduría, perseverancia, congruencia y paciencia, mismos que nos comprometemos a impulsar, para que el cambio se vea reflejado en nuestra institución.</p>

        <p className='pBienvenida'>A los docentes, investigadores, trabajadores, administrativos, les invito a que juntos hagamos que las cosas sean una realidad.</p>

        <p className='pBienvenida'>Agradezco la oportunidad de la vida por estar aquí con ustedes, agradezco el recibimiento tan cálido que me ha brindado esta noble institución, agradezco al Gobernador del Estado de Jalisco por la confianza, al Secretario de Innovación, Ciencia y Tecnología de Jalisco por el apoyo y a toda la comunidad #TecSJ.</p>

    </PrincipalContainer>
  )
}
